export const useModalStore = defineStore('modalStore', {
  state: () => ({
    modalStatus: false,
    globalModal: { ...initialState },
  }),
  actions: {
    setGlobalModal(payload: any) {
      this.globalModal = payload;
    },
    setModalStatus(payload: boolean) {
      this.modalStatus = payload;
    },
    closeGlobalModal() {
      this.globalModal = { ...initialState };
    },
  },
});

const initialState: any = {
  title: '',
  message: '',
  icon: '',
  type: '',
  show: false,
  okText: 'Ok',
  cancelText: 'Cancel',
  cssClass: '',
};
